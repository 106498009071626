<script setup lang="ts">
    const appStore = useAppStore();
    const { website } = storeToRefs(appStore);

    const social = computed(() => website.value?.social);

    const socialIcons = computed(() => {
        if (!social.value) return [];

        const icons = Object.keys(social.value)
            .filter((p) => p !== '__typename')
            .map((p) => {
                return {
                    key: p,
                    url: (social.value as Record<string, any>)[p],
                    icon: 'woom-icon-' + p,
                };
            })
            .filter((p) => p.url);
        return icons;
    });
</script>

<template>
    <div class="atm-social-links flex flex-wrap gap-3 md:gap-5">
        <template
            v-for="network in socialIcons"
            :key="network.key">
            <nuxt-link
                :to="network.url"
                class="transition-colors hover:text-woom-red"
                target="_blank"
                :aria-label="`Visit our ${network.key} profile`">
                <component
                    :is="network.icon"
                    class="h-9 w-9 fill-current md:h-10 md:w-10" />
            </nuxt-link>
        </template>
    </div>
</template>
